import { VStack, Text, Flex, Box, Token } from '@revolut/ui-kit'
import React from 'react'
import { formatPercentage } from '@src/utils/format'
import { formatIfNumber } from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { GradeDistributionItemData } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/Items'

export const Tooltip = (
  props: GradeDistributionItemData,
  dataKeys?: DataKey<any>[],
  colors?: string[],
) => {
  const values = (dataKeys ?? []).map((dataKey, id) => {
    const percentage =
      Number(props[dataKey.value as keyof GradeDistributionItemData] ?? 0) / 100
    const count = Math.round(percentage * props.totalGradesCount)
    const inGroupPercentage = count / props.gradeCount

    return {
      ...dataKey,
      count,
      percentage: inGroupPercentage,
      color: colors ? colors[id] : dataKey.color,
    }
  })

  return (
    <VStack space="s-4">
      <Text variant="h6">{props.label}</Text>
      {values.map((value, id) => (
        <Flex justifyContent="flex-start" key={id} alignItems="center" gap="s-6">
          <Box
            bg={value.color}
            width="s-12"
            height="s-12"
            border={`1px solid ${Token.color.greyTone50}`}
            borderRadius={Token.radius.r2}
          />
          <Text fontSize="small" color={Token.color.background}>
            {value.label}:
          </Text>
          <Text fontSize="small" color={Token.color.background} ml="auto">
            {formatIfNumber(value.count)}
          </Text>
          {value.percentage > 0 && (
            <Text fontSize="small" color={Token.color.background}>
              {value.percentage > 0.01
                ? `(${formatPercentage(value.percentage)})`
                : '(<1%)'}
            </Text>
          )}
        </Flex>
      ))}
    </VStack>
  )
}
