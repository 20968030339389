import React, { useMemo, useRef } from 'react'
import { Dropdown, HStack, Icon, Text, TextButton, useToggle } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { PerformanceReviewAnalyticsGroupTypes } from '@src/interfaces/performance'

interface Props {
  value: BaseOption<PerformanceReviewAnalyticsGroupTypes>
  onSelect: (id: PerformanceReviewAnalyticsGroupTypes) => void
}

export const GroupSelector = ({ value, onSelect }: Props) => {
  const groupDropdownRef = useRef(null)
  const [isGroupDropdownOpen, groupDropdownToggler] = useToggle()

  const options = useMemo(
    () => groupOptions.filter(({ id }) => id !== value.id),
    [value?.id],
  )

  return (
    <>
      <TextButton ref={groupDropdownRef} onClick={groupDropdownToggler.switch}>
        <HStack space="s-4" align="center">
          <Text variant="emphasis1">{value.name}</Text>
          <Icon size={16} name={isGroupDropdownOpen ? 'ChevronUp' : 'ChevronDown'} />
        </HStack>
      </TextButton>
      <Dropdown
        open={isGroupDropdownOpen}
        anchorRef={groupDropdownRef}
        onClose={groupDropdownToggler.off}
        autoClose
      >
        {options.map(({ id, name }) => (
          <Dropdown.Item key={id} use="button" onClick={() => onSelect(id)}>
            {name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}

export const groupOptions: BaseOption<PerformanceReviewAnalyticsGroupTypes>[] = [
  {
    id: PerformanceReviewAnalyticsGroupTypes.Country,
    name: 'Country',
  },
  {
    id: PerformanceReviewAnalyticsGroupTypes.Seniority,
    name: 'Seniority',
  },
  {
    id: PerformanceReviewAnalyticsGroupTypes.Department,
    name: 'Department',
  },
  {
    id: PerformanceReviewAnalyticsGroupTypes.Function,
    name: 'Function',
  },
  {
    id: PerformanceReviewAnalyticsGroupTypes.Role,
    name: 'Role',
  },
  {
    id: PerformanceReviewAnalyticsGroupTypes.Specialisation,
    name: 'Specialisation',
  },
]
