import React from 'react'
import {
  Widget,
  Text,
  VStack,
  HStack,
  Bullet,
  Token,
  Link,
  InputGroup,
  ActionButton,
  Avatar,
} from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import {
  OKTA_REVOLUT_PEOPLE_INTEGRATION,
  REVOLUT_PEOPLE_OKTA_DOCUMENTATION,
} from '@src/constants/externalLinks'
import Form from '@src/features/Form/Form'
import { oktaIntegrationPreferences } from '@src/api/integrations'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OktaIntegrationInterface } from '@src/interfaces/integrations'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'

export const OktaIntegration = () => {
  return (
    <Form api={oktaIntegrationPreferences} forceParams={{ id: '1' }}>
      <OktaIntegrationForm />
    </Form>
  )
}

const OktaIntegrationForm = () => {
  const isCommercial = useIsCommercial()
  const queryClient = useQueryClient()
  const { values } = useLapeContext<OktaIntegrationInterface>()
  const permissions = useSelector(selectPermissions)
  const disabled = !permissions.includes(PermissionTypes.ChangeOktaIntegration)

  return (
    <PageWrapper maxWidth={Token.breakpoint.md}>
      <PageHeader
        title="Okta"
        subtitle="Collaboration"
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
        avatar={
          <Avatar
            image="https://assets.revolut.com/revoluters/icons/okta-76.png"
            variant="brand"
            bg="white"
          />
        }
      >
        <ActionButton
          href={OKTA_REVOLUT_PEOPLE_INTEGRATION}
          use="a"
          target="_blank"
          useIcon="LinkExternal"
          rel="noreferrer noopener"
          variant="accent"
        >
          Manage in Okta
        </ActionButton>
      </PageHeader>

      <PageBody mb="s-64" mt="s-16">
        <Widget p="s-16" mb="s-16">
          <Text variant="h5" display="block" mb="s-16">
            About integration
          </Text>

          <Text variant="caption" display="block">
            Okta is an identity and access management solution that links all your apps,
            logins and devices into one. Revolut People’s Okta integration supports single
            sign-on.
          </Text>

          <Text variant="h5" display="block" my="s-16">
            How to use
          </Text>

          <VStack gap="s-16" mt="s-24">
            <HStack gap="s-16">
              <Bullet variant="filled">1</Bullet>
              <Text color={Token.color.greyTone50}>
                Make sure you have administrator access to your Okta account and the IT
                Admin role in Revolut People
              </Text>
            </HStack>

            {isCommercial ? (
              <HStack gap="s-16">
                <Bullet variant="filled">2</Bullet>
                <Text color={Token.color.greyTone50}>
                  Click the ‘Manage in Okta’ button and follow our{' '}
                  <Link
                    use="a"
                    rel="noreferrer noopener"
                    target="_blank"
                    href={REVOLUT_PEOPLE_OKTA_DOCUMENTATION}
                  >
                    step-by-step guide
                  </Link>{' '}
                  to set up the integration
                </Text>
              </HStack>
            ) : null}

            <HStack gap="s-16">
              <Bullet variant="filled">{isCommercial ? 3 : 2}</Bullet>
              <Text color={Token.color.greyTone50}>
                After the integration is set up, enable the toggle below, paste the
                metadata URL in the field, and save the changes
              </Text>
            </HStack>

            <HStack gap="s-16">
              <Bullet variant="filled">{isCommercial ? 4 : 3}</Bullet>
              <Text color={Token.color.greyTone50}>
                Turn on ‘Login with Okta’ in{' '}
                <Link use={InternalLink} to={ROUTES.SETTINGS.SECURITY}>
                  Security Settings
                </Link>
              </Text>
            </HStack>
          </VStack>
        </Widget>

        <InputGroup>
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Enable Okta integration',
              description: 'Connect with Okta to unlock Single Sign-On.',
            }}
            name="enabled"
            disabled={disabled}
          />
          {values.enabled ? (
            <LapeNewInput
              required
              name="metadata_url"
              label="Metadata URL"
              description={
                <>
                  The URL used by Revolut People to send information about users to Okta.
                  Learn more in our{' '}
                  <Link
                    use="a"
                    rel="noreferrer noopener"
                    target="_blank"
                    href={REVOLUT_PEOPLE_OKTA_DOCUMENTATION}
                  >
                    SSO guide
                  </Link>
                </>
              }
              disabled={disabled}
            />
          ) : null}
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.OKTA_INTEGRATION_PREFERENCES)
          }}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}
