import React, { useEffect, useState } from 'react'
import { Box, VStack, Widget } from '@revolut/ui-kit'
import CheckboxCard from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/CheckboxCard'
import { SummaryCard } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/SummaryCard/SummaryCard'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackSection,
  InterviewFeedbackSkillSectionInterface,
  InterviewFeedbackSummaryInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import RadioCard from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/RadioCard'
import { getThumbsQuestionPoints } from '@src/utils/interview'
import ThumbsCard from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/ThumbsCard'
import { LevelsCard } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/LevelsCard'
import { ResultEvaluationSection } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/ResultEvaluationSection'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import { generateSummary } from '@src/api/recruitment/interviewFeedback'
import DropdownCard from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/DropdownCard'
import { useGetHiringProcessSettings } from '@src/api/settings'
import LocationsQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/LocationsQuestionSection'
import SalaryQuestionSection, {
  SalaryQuestionSectionValues,
} from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/SalaryQuestionSection'
import { getHiringSectionIndexes } from '@src/pages/Settings/HiringProcess/utils'
import { setTimeCodeHandler } from '@src/utils/hiring'
import RightToWorkQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/RightToWorkQuestionSection'
import { ItemSwitch } from '@components/Inputs/ItemSwitch/ItemSwitch'
import { Section } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/Section'
import SectionHeader from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/SectionHeader'

interface Props {
  isSidebar?: boolean
  isViewMode?: boolean
  stageType?: InterviewType | null
}

interface InterviewFeedbackFormBodyProps extends Props {
  errors?: FormError<InterviewFeedbackInterface>
  showCards?: boolean
  values: InterviewFeedbackInterface
}

export const InterviewFeedbackFormBody = ({
  errors,
  isSidebar,
  isViewMode,
  stageType,
  showCards = true,
  values,
}: InterviewFeedbackFormBodyProps) => {
  const [summary, setSummary] = useState<InterviewFeedbackSummaryInterface>()
  const [showFeedbackForCandidate, setShowFeedbackForCandidate] = useState(
    !!values.notes_for_candidate,
  )

  const isScreenCall = stageType === 'screen_call'
  const { data: settings } = useGetHiringProcessSettings(!isScreenCall)

  const data = values?.scorecard.sections
  const seniorities = values?.scorecard.seniority_section
  const passScore = values.pass_score
  const maxScore = values.maximum_points
  const type = values.scorecard.scorecard_type
  const feedbackId = values.id

  const points =
    data?.reduce((sum, s) => {
      switch (s.internal_data_type) {
        case 'dropdown_question_interview_section':
        case 'options_question_interview_section': {
          sum += s.answer?.points || 0
          break
        }
        case 'checkbox_questions_interview_section': {
          sum +=
            s.questions.reduce((acc, val) => (val.answer ? acc + val.points : acc), 0) ||
            0
          break
        }

        case 'thumbs_questions_interview_section': {
          sum +=
            s.questions.reduce((acc, val) => acc + getThumbsQuestionPoints(val), 0) || 0
          break
        }
      }

      return sum
    }, 0) || 0

  const updateSummary = async () => {
    if (!feedbackId) {
      return
    }
    const resp = await generateSummary(feedbackId, values)
    setSummary(resp.data)
  }

  const getDependencies = () => {
    const list = [
      values.result,
      values.rating,
      values.evaluated_seniority?.level,
      values.scorecard.seniority_section?.answer,
    ]
    if (
      values.scorecard.scorecard_type === 'general' ||
      values.scorecard.scorecard_type === 'cv_screening'
    ) {
      return [...list, points]
    }

    const getSkillInterviewSections = (sections: InterviewFeedbackSection[]) =>
      sections.filter(
        val => val.internal_data_type === 'skill_interview_section',
        // we have a union of types, but for some reason TS doesn't pick it up
      ) as InterviewFeedbackSkillSectionInterface[]

    const answers = getSkillInterviewSections(values.scorecard.sections || []).flatMap(
      section => section.sections?.flatMap(sec => sec.items?.flatMap(item => item.value)),
    )

    return [...list, ...answers]
  }

  useEffect(() => {
    updateSummary()
  }, getDependencies())

  const getLevelsCardErrors = (index: number) => {
    const sectionError = errors?.scorecard?.sections?.[index] as
      | FormError<InterviewFeedbackSkillSectionInterface>
      | undefined

    return {
      sections: sectionError?.levels_questions?.map(
        error => error?.questions?.find(question => question?.answer)?.answer || '',
      ),
    }
  }

  const { predefinedSectionsLength } = getHiringSectionIndexes(
    isScreenCall,
    settings,
    data,
  )

  return (
    <VStack gap="s-24">
      {isScreenCall && (
        <>
          {settings?.enable_location_collecting && (
            <LocationsQuestionSection<InterviewFeedbackInterface>
              optional={settings.is_location_optional}
              disabled={isViewMode}
              location={values.preferred_location}
              country={values.current_country}
              onChangeCountry={val => {
                if (!isViewMode) {
                  values.current_country = val
                  if (errors) {
                    delete errors.current_country
                  }
                }
              }}
              onChangeLocation={val => {
                if (!isViewMode) {
                  values.preferred_location = val
                  if (errors) {
                    delete errors.preferred_location
                  }
                }
              }}
              errors={errors}
              names={{
                location: 'preferred_location',
                country: 'current_country',
              }}
            />
          )}
          {settings?.enable_compensation_collecting && (
            <SalaryQuestionSection
              optional={settings.is_compensation_optional}
              values={{
                current_salary_frequency: values.current_salary_frequency,
                current_salary_amount: values.current_salary_amount,
                current_salary_currency: values.current_salary_currency,
                additional_information_about_current_salary:
                  values.additional_information_about_current_salary,
                candidate_declined_to_disclose_current_salary:
                  values.candidate_declined_to_disclose_current_salary,
                expected_salary_frequency: values.expected_salary_frequency,
                expected_salary_amount: values.expected_salary_amount,
                expected_salary_currency: values.expected_salary_currency,
                additional_information_about_expected_salary:
                  values.additional_information_about_expected_salary,
                candidate_declined_to_disclose_expected_salary:
                  values.candidate_declined_to_disclose_expected_salary,
              }}
              onChange={obj => {
                if (!isViewMode) {
                  Object.assign(values, obj)

                  Object.keys(obj).forEach(k => {
                    const key = k as keyof SalaryQuestionSectionValues
                    if (obj[key] && errors) {
                      delete errors[key]
                    }
                  })
                }
              }}
              disabled={isViewMode}
              errors={errors}
            />
          )}
          {settings?.enable_right_to_work_collecting && (
            <RightToWorkQuestionSection
              optional={settings.is_compensation_optional}
              rightToWork={values.right_to_work}
              isEligibleToRelocate={values.is_eligible_to_relocate}
              isReadyToRelocate={values.is_ready_to_relocate}
              onChangeIsEligibleToRelocate={v => {
                values.is_eligible_to_relocate = v
              }}
              onChangeIsReadyToRelocate={v => {
                values.is_ready_to_relocate = v
              }}
              onChangeRightToWork={v => {
                values.right_to_work = v
              }}
              disabled={isViewMode}
              errors={{
                right_to_work: errors?.right_to_work,
                is_eligible_to_relocate: errors?.is_eligible_to_relocate,
                is_ready_to_relocate: errors?.is_ready_to_relocate,
              }}
            />
          )}
        </>
      )}

      {showCards &&
        data?.map((card, index) => {
          const titleIndexNumber = predefinedSectionsLength + index + 1
          return (
            <React.Fragment key={index}>
              {card.internal_data_type === 'checkbox_questions_interview_section' && (
                <CheckboxCard
                  name={`scorecard.sections.${index}.questions`}
                  notesName={`scorecard.sections.${index}.notes`}
                  data={card.questions}
                  isViewMode={isViewMode}
                  isOptional={card.optional}
                  title={card.title}
                  subtitle={card.sub_title}
                  titleIndexNumber={titleIndexNumber}
                />
              )}
              {card.internal_data_type === 'text_question_interview_section' && (
                <Section
                  index={titleIndexNumber}
                  title={card.title}
                  isOptional={card.optional}
                  subtitle={card.sub_title}
                  key={index}
                  onAddTimeCode={setTimeCodeHandler(
                    `scorecard.sections.${index}.answer`,
                    values,
                  )}
                >
                  <LapeNewTextArea
                    name={`scorecard.sections.${index}.answer`}
                    data-testid={`scorecard.sections.${index}.answer`}
                    label={card.placeholder}
                    rows={3}
                    readOnly={isViewMode}
                    required
                  />
                </Section>
              )}
              {card.internal_data_type === 'options_question_interview_section' && (
                <RadioCard
                  answerName={`scorecard.sections.${index}.answer`}
                  notesName={`scorecard.sections.${index}.notes`}
                  data={card.options}
                  isViewMode={isViewMode}
                  isOptional={card.optional}
                  title={card.title}
                  subtitle={card.sub_title}
                  titleIndexNumber={titleIndexNumber}
                />
              )}
              {card.internal_data_type === 'dropdown_question_interview_section' && (
                <DropdownCard
                  answerName={`scorecard.sections.${index}.answer`}
                  notesName={`scorecard.sections.${index}.notes`}
                  placeholder={card.placeholder}
                  data={card.options}
                  isViewMode={isViewMode}
                  isOptional={card.optional}
                  title={card.title}
                  subtitle={card.sub_title}
                  titleIndexNumber={titleIndexNumber}
                />
              )}
              {card.internal_data_type === 'thumbs_questions_interview_section' && (
                <ThumbsCard
                  name={`scorecard.sections.${index}.questions`}
                  notesName={`scorecard.sections.${index}.notes`}
                  data={card.questions}
                  isViewMode={isViewMode}
                  isOptional={card.optional}
                  title={card.title}
                  subtitle={card.sub_title}
                  titleIndexNumber={titleIndexNumber}
                />
              )}
              {card.internal_data_type === 'skill_interview_section' && (
                <LevelsCard
                  sections={card.sections || []}
                  skills={card.skills}
                  title={card.title}
                  subTitle={card.sub_title}
                  notes={card.notes}
                  name={`scorecard.sections.${index}.sections`}
                  isViewMode={isViewMode}
                  index={index}
                  errors={getLevelsCardErrors(index)}
                  titleIndexNumber={titleIndexNumber}
                />
              )}
            </React.Fragment>
          )
        })}
      <SummaryCard
        points={points}
        onlyTotal={isSidebar}
        passScore={passScore}
        maxScore={maxScore}
        type={type}
        summary={summary}
      />
      <Widget p="s-16">
        <VStack gap="s-24">
          <ResultEvaluationSection
            levelsPoints={values.scorecard.levels_points}
            type={type}
            seniorities={seniorities}
            suggestedLevel={summary?.suggested_level}
            suggestedResult={summary?.hire_recommendation}
            isViewMode={isViewMode}
            deviationJustificationRequired={summary?.deviation_justification_required}
          />
          <Box>
            <SectionHeader title="Overall impression" />
            <LapeNewTextArea
              name="overall_impressions"
              data-testid="overall_impressions"
              label="Enter a short summary of your assessment of the candidate"
              rows={3}
              readOnly={isViewMode}
              required
              description="This feedback is only visible internally"
            />
          </Box>
        </VStack>
      </Widget>
      <Widget>
        <ItemSwitch
          title="Leave Feedback for the candidate"
          checked={showFeedbackForCandidate}
          onChange={() => setShowFeedbackForCandidate(!showFeedbackForCandidate)}
        />
        {showFeedbackForCandidate && (
          <Box p="s-16">
            <LapeNewTextArea
              name="notes_for_candidate"
              data-testid="notes_for_candidate"
              label="Feedback for candidate"
              rows={3}
              readOnly={isViewMode}
              description="Please note that this feedback can be shared directly with the candidate by the recruiter "
            />
          </Box>
        )}
      </Widget>
    </VStack>
  )
}

export const InterviewFeedbackFormV2 = (props: Props) => {
  const { values, errors } = useLapeContext<InterviewFeedbackInterface>()
  return <InterviewFeedbackFormBody {...props} errors={errors} values={values} />
}
