import React, { useMemo, useState } from 'react'
import { useToggle } from '@revolut/ui-kit'

import { engagementAnswersTableRequests } from '@src/api/engagement'
import SideBar from '@src/components/SideBar/SideBar'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsV2AnsweredOnColumn,
  engagementResultsV2AnswerScoreColumn,
  engagementResultsV2AnswerTextColumn,
  engagementResultsV2CategoryColumn,
  engagementResultsV2QuestionTextColumn,
  isEmptyAnswerTextColName,
} from '@src/constants/columns/engagementResultsV2'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EngagementAnswerInterface } from '@src/interfaces/engagement'
import { toIdAndName } from '@src/utils/toIdAndName'
import { ResultsInterface } from '../common'
import { AcknowledgementSidebar } from './AcknowledgementSidebar'
import {
  getDateRangeFilterParams,
  useApplyNonTableFilters,
} from '../hooks/useApplyNonTableFilters'

const getRow = (
  onClickRow: (rowData: EngagementAnswerInterface) => void,
): RowInterface<EngagementAnswerInterface> => ({
  linkToForm: data => {
    onClickRow(data)
  },
  cells: [
    {
      ...engagementResultsV2QuestionTextColumn,
      width: 400,
    },
    {
      ...engagementResultsV2AnswerTextColumn,
      width: 400,
    },
    {
      ...engagementResultsV2CategoryColumn,
      width: 200,
    },
    {
      ...engagementResultsV2AnswerScoreColumn,
      width: 120,
    },
    {
      ...engagementResultsV2AnsweredOnColumn,
      width: 120,
    },
  ],
})

export const ResultAnswersTable = ({
  surveyId,
  timelineFilter,
  scopeFilters,
}: ResultsInterface) => {
  const [sidebarOpen, toggleSidebarOpen] = useToggle()
  const [selectedAnswer, setSelectedAnswer] = useState<EngagementAnswerInterface>()

  const table = useTable<EngagementAnswerInterface>(
    engagementAnswersTableRequests,
    [
      {
        columnName: 'survey__id',
        filters: [toIdAndName(String(surveyId))],
        nonResettable: true,
      },
      {
        columnName: isEmptyAnswerTextColName,
        filters: [toIdAndName('false')],
        nonResettable: true,
      },
      ...getDateRangeFilterParams(
        timelineFilter.dateFrom,
        timelineFilter.dateTo,
        'answers',
      ),
      ...(scopeFilters || []),
    ],
    undefined,
    { disableQuery: true },
  )

  useApplyNonTableFilters({
    disable: table.loading,
    table,
    timelineFilter,
    scopeFilters,
    itemsToAnalyse: 'answers',
  })

  const row = useMemo(() => {
    const isScopedView = !!scopeFilters

    return getRow(data => {
      if (isScopedView) {
        return
      }
      setSelectedAnswer(data)
      toggleSidebarOpen.on()
    })
  }, [])

  return (
    <>
      <AdjustableTable
        {...table}
        hideCount
        useWindowScroll
        dataType="Comment"
        name={TableNames.EngagementSurveysResultComments}
        emptyState={<EmptyTableRaw title="No answers found" />}
        row={row}
      />
      {selectedAnswer && (
        <SideBar
          variant="medium"
          isOpen={sidebarOpen}
          onClose={toggleSidebarOpen.off}
          title={selectedAnswer?.question.question_text}
        >
          <AcknowledgementSidebar
            answerId={selectedAnswer.id}
            closeSidebar={toggleSidebarOpen.off}
            onAcknowledge={() => table.refresh()}
          />
        </SideBar>
      )}
    </>
  )
}
