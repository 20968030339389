import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useQuery } from '@src/utils/queryParamsHooks'
import { confirmIndeedIntegration, useGetJobPostingSettings } from '@src/api/settings'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { Box, Group, Link, Text, Token, VStack } from '@revolut/ui-kit'
import { ConnectIndeed } from '@src/pages/Hub/Integrations/Indeed/ConnectIndeed'
import { INDEED } from '@src/constants/externalLinks'
import { PostJobsOnIndeed } from '@src/pages/Hub/Integrations/Indeed/PostJobsOnIndeed'
import { IndeedApply } from '@src/pages/Hub/Integrations/Indeed/IndeedApply'
import { getRedirectHost } from '@src/constants/api'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { DisconnectIndeed } from '@src/pages/Hub/Integrations/Indeed/DisconnectIndeed'

const CODE_QUERY_KEY = 'code'
const STATE_QUERY_KEY = 'state'

type IndeedIntegrationQueryParams = {
  [CODE_QUERY_KEY]: string
  [STATE_QUERY_KEY]: string
}

export const IndeedIntegrationPage = () => {
  const { query, deleteQueryParam } = useQuery<IndeedIntegrationQueryParams>()
  const {
    data: jobPostingSettings,
    isLoading: loading,
    refetch,
  } = useGetJobPostingSettings()
  const showStatusPopup = useShowStatusPopup()
  const confirmationPopup = useConfirmationDialog()

  useEffect(() => {
    const { state, code } = query

    if (state && code) {
      confirmationPopup.show({
        label: 'Enable Indeed integration',
        body: 'Press Confirm button to finish integration process',
        yesMessage: 'Confirm',
        noMessage: 'Cancel',
        onConfirm: async () => {
          try {
            await confirmIndeedIntegration(
              state,
              code,
              `${getRedirectHost()}${PUBLIC_ROUTES.INDEED_INTEGRATION_REDIRECT}`,
            )
            showStatusPopup({
              title: 'Indeed integration sucessfully enabled',
              status: 'success',
            })
            deleteQueryParam(CODE_QUERY_KEY)
            deleteQueryParam(STATE_QUERY_KEY)
            refetch()
          } catch (error) {
            showStatusPopup({
              title: 'Could not enable Indeed integration',
              description: getStringMessageFromError(error),
              status: 'error',
            })
          }
        },
      })
    }
  }, [])

  if (loading) {
    return <PageLoading />
  }
  return (
    <PageWrapper>
      <PageHeader
        title="Indeed"
        subtitle={
          <VStack>
            {jobPostingSettings?.indeed_connected && (
              <Text color={Token.color.success} variant="emphasis1">
                Connected
              </Text>
            )}
            <Text color={Token.color.greyTone50} variant="body2">
              Job boards
            </Text>
          </VStack>
        }
        backUrl={ROUTES.FEATURES.INTEGRATIONS}
      >
        <ConnectIndeed />
      </PageHeader>
      <PageBody>
        <OnboardingIntro>
          {jobPostingSettings?.indeed_connected && (
            <Group>
              <Box p="s-16">
                <Text variant="emphasis2" color={Token.color.greyTone50}>
                  Integration settings
                </Text>
              </Box>
              <PostJobsOnIndeed />
              <IndeedApply />
            </Group>
          )}
          <Group>
            <OnboardingIntroTextWidget
              title={
                <FormattedMessage
                  id="integrations.indeed.intro.title"
                  defaultMessage="About integration"
                />
              }
            >
              <Text variant="body2">
                <FormattedMessage
                  id="integrations.indeed.intro.description"
                  defaultMessage="Automatically post all the jobs published on your Careers website on {indeedSite} job board to increase the amount of applicants you attract."
                  values={{
                    indeedSite: (
                      <Link href={INDEED} target="_blank">
                        indeed.com
                      </Link>
                    ),
                  }}
                />
              </Text>
            </OnboardingIntroTextWidget>
            <OnboardingIntroTips
              title={
                <FormattedMessage
                  id="integrations.indeed.intro.tips.title"
                  defaultMessage="How to use"
                />
              }
              items={[
                {
                  icon: 'Plug',
                  title: (
                    <FormattedMessage
                      id="integrations.indeed.intro.tips.connect.title"
                      defaultMessage="Connect your Indeed account"
                    />
                  ),
                  text: (
                    <FormattedMessage
                      id="integrations.indeed.intro.tips.connect.description"
                      defaultMessage="You will be redirected to Indeed and prompted to log in with your credentials"
                    />
                  ),
                },
                {
                  icon: 'AutoExchange',
                  title: (
                    <FormattedMessage
                      id="integrations.indeed.intro.tips.post.title"
                      defaultMessage="Post your jobs on Indeed"
                    />
                  ),
                  text: (
                    <FormattedMessage
                      id="integrations.indeed.intro.tips.post.description"
                      defaultMessage="Create jobs on Revolut People and Job Sync will automatically include them in Indeed’s search results without further action on your part. This can help expand the reach of your jobs without any additional effort."
                    />
                  ),
                },
                {
                  icon: 'DocumentsPair',
                  title: (
                    <FormattedMessage
                      id="integrations.indeed.intro.tips.apply.title"
                      defaultMessage="Apply with Indeed"
                    />
                  ),
                  text: (
                    <FormattedMessage
                      id="integrations.indeed.intro.tips.apply.description"
                      defaultMessage="Automatically enable candidates to apply to your Revolut People jobs on Indeed with easy application process, Indeed Apply. Indeed applications automatically sync to Revolut People"
                    />
                  ),
                },
              ]}
            />
          </Group>
          {jobPostingSettings?.indeed_connected && (
            <DisconnectIndeed
              onRefresh={() => {
                refetch()
              }}
            />
          )}
        </OnboardingIntro>
      </PageBody>
    </PageWrapper>
  )
}
