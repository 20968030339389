import React from 'react'
import { ActionButton, Flex, Group, Item, Placeholder, Widget } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SummarySection } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/SummarySection'
import Loader from '@components/CommonSC/Loader'
import {
  emptySummaryDescription,
  makeSummaryTitle,
  summaryDescription,
} from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/constants'
import { useGetPerformanceSummaryForGrowthPlan } from '@src/api/growthPlans'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { FunctionalSkillsTable } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Skills/FunctionalSkillsTable'
import { CulturalValuesTable } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Values/CulturalValuesTable'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getReviewsUrl } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import { useGetPerformanceReviewSummary } from '@src/api/performanceReview'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { useIsGrowthPlansEnabled } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { PerformanceSelector } from '@src/interfaces/performance'
import { Deliverables } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/Deliverables'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
  reviewCycle: PerformanceSelector | null
  isLoading: boolean
}

const placeholderHeight = 300

export const Summary = ({ employee, growthPlan, reviewCycle, isLoading }: Props) => {
  const isGrowthPlansEnabled = useIsGrowthPlansEnabled()
  const {
    data: growthPlanPerformanceSummary,
    isLoading: isGrowthPlanPerformanceSummaryLoading,
  } = useGetPerformanceSummaryForGrowthPlan(
    {
      employee_id: employee.id,
      target_seniority_id: growthPlan.target_seniority.id,
      target_specialisation_id: growthPlan.target_specialisation.id,
      cycle_id: reviewCycle?.cycle_id,
      cycle_category: reviewCycle?.category,
    },
    isGrowthPlansEnabled &&
      !isLoading &&
      !!reviewCycle?.cycle_id &&
      !!reviewCycle?.category,
  )
  const { data: performanceSummary, isLoading: isPerformanceSummaryLoading } =
    useGetPerformanceReviewSummary(
      reviewCycle ? String(reviewCycle.id) : undefined,
      employee.id,
      reviewCycle?.category,
    )

  if (isLoading || isPerformanceSummaryLoading) {
    return (
      <Widget height={placeholderHeight}>
        <Loader />
      </Widget>
    )
  }

  if (reviewCycle === null) {
    return (
      <Widget height={placeholderHeight}>
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Placeholder>
            <Placeholder.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-business/3D299.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-business/3D299@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-business/3D299@3x.png',
              }}
              size={88}
            />
            <Placeholder.Title>{emptySummaryDescription}</Placeholder.Title>
          </Placeholder>
        </Flex>
      </Widget>
    )
  }

  return (
    <Widget>
      <Group style={transparentThemeBackgroundOverrides}>
        <Item>
          <Item.Content>
            <Item.Title>{makeSummaryTitle(reviewCycle)}</Item.Title>
            <Item.Description>{summaryDescription}</Item.Description>
          </Item.Content>
          <Item.Side>
            <Item.Value>
              <ActionButton
                useIcon="LinkExternalSimple"
                to={getReviewsUrl(
                  reviewCycle.category,
                  String(reviewCycle.id),
                  employee.id,
                )}
                target="_blank"
                use={InternalLink}
                disabled={isLoading}
              >
                Detailed view
              </ActionButton>
            </Item.Value>
          </Item.Side>
        </Item>
        <SummarySection title="Goals" iconName="Target">
          <Deliverables
            employee={employee}
            performanceSummary={performanceSummary}
            reviewCycle={reviewCycle}
          />
        </SummarySection>
        <SummarySection title="Skills" iconName="Palette">
          <FunctionalSkillsTable
            data={growthPlanPerformanceSummary?.functional_skills ?? []}
            growthPlan={growthPlan}
            isLoading={isLoading || isGrowthPlanPerformanceSummaryLoading}
          />
        </SummarySection>
        <SummarySection title="Values" iconName="Heart">
          <CulturalValuesTable
            data={growthPlanPerformanceSummary?.culture_values ?? []}
            growthPlan={growthPlan}
            isLoading={isLoading || isGrowthPlanPerformanceSummaryLoading}
          />
        </SummarySection>
      </Group>
    </Widget>
  )
}
