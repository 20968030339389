import React from 'react'
import { Token } from '@revolut/ui-kit'
import { GradeDistributionItemData } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/Items'
import { formatPercentage } from '@src/utils/format'

interface Props {
  x: number
  y: number
  payload: { value: string }
  data: GradeDistributionItemData[]
}

export const XAxisTick = ({ x, y, payload, data }: Props) => {
  const tickData = data.find(({ label }) => !!payload.value && label === payload.value)

  if (!tickData) {
    return null
  }

  return (
    <text pointerEvents="none" textAnchor="middle">
      <tspan x={x} y={y} dy="0.5em" fill={Token.color.greyTone50} fontSize={14}>
        {tickData.label}
      </tspan>
      {!!tickData.gradeCount && (
        <tspan x={x} y={y} dy="1.75em" fill={Token.color.greyTone50} fontSize={12}>
          {`${tickData.gradeCount} (${formatPercentage(
            tickData.gradeCount / tickData.totalGradesCount,
          )})`}
        </tspan>
      )}
    </text>
  )
}
