import {
  Side,
  InputGroup,
  TextButton,
  Cell,
  VStack,
  Box,
  TransitionCollapse,
  Text,
  Token,
} from '@revolut/ui-kit'
import React, { useEffect, ChangeEvent, useState } from 'react'
import { ticketsRequests } from '@src/api/tickets'
import Form from '@src/features/Form/Form'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TicketInterface } from '@src/interfaces/tickets'
import SideBar from '@components/SideBar/SideBar'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'

interface BugReportSideProps {
  open: boolean
  onClose: () => void
}

interface BugReportFormProps {
  onClose: () => void
}

const getExtraInfo = () => {
  return `UserAgent: ${window.navigator.userAgent}`
}

const getUrl = () => {
  let url = new URL(window.location.href)
  url.searchParams.delete('help')
  return url.href
}

const BugReportForm = ({ onClose }: BugReportFormProps) => {
  const { values } = useLapeContext<TicketInterface>()
  const [shareUrl, setShareUrl] = useState(true)

  useEffect(() => {
    if (!values.url) {
      values.url = getUrl()
    }
  }, [window.location.href])

  useEffect(() => {
    values.extra_info = getExtraInfo()
    values.is_extra_info_shared = true
  }, [])

  const handleExtraInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    values.is_extra_info_shared = e.currentTarget.checked
    values.extra_info = e.currentTarget.checked ? getExtraInfo() : undefined
  }

  const handleUrlShareChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShareUrl(e.currentTarget.checked)
    values.url = e.currentTarget.checked ? getUrl() : undefined
  }

  return (
    <>
      <InputGroup>
        <LapeNewInput required name="title" label="Tell us what’s wrong" />
        <LapeNewTextArea
          label="Issue description"
          required
          placeholder="Example: I need to create a requisitions, but when I go in the table and try to create it I get an error"
          rows={3}
          name="text"
        />
        <LapeRadioSelectInput
          label="Topic"
          name="product"
          selector={selectorKeys.ticket_products}
        />
        <LapeNewTextArea
          label="Steps to reproduce"
          required
          name="steps_to_reproduce"
          placeholder={`Example:\n• Go to the requisitions page\n• Click create requisition\n• Fill in all the fields in the form\n• Click Submit\n• Expected behaviour: Requisitions gets created\n• Actual behaviour: Requisition is not created, instead I see an internal server error`}
          rows={3}
        />
        <LapeFileUploader
          name="files"
          accept={
            'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, text/csv, application/csv, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, video/quicktime, video/mp4'
          }
          uploadInstructions={
            <VStack align="center" mt="s-4">
              <Text variant="small" color={Token.color.greyTone50}>
                Allowed file-types: .pdf, .jpeg, .jpg, .png, .xlsx, .csv, .docx, .mov,
                .mp4
              </Text>
              <Text variant="small" color={Token.color.greyTone50}>
                Max size 20MB
              </Text>
            </VStack>
          }
          attachButtonText="Click to attach files"
          multiple
        />
        <InputGroup>
          <Cell use="label">
            <VStack width="100%">
              <NewCheckbox
                label="Share URL where the issue happened"
                checked={shareUrl}
                onChange={handleUrlShareChange}
              />
              <TransitionCollapse in={shareUrl}>
                <Box pt="s-8">
                  <LapeNewInput name="url" label="URL" required />
                </Box>
              </TransitionCollapse>
            </VStack>
          </Cell>
          <LapeSingleCheckbox
            name="is_extra_info_shared"
            label="Share activity logs in Revolut People"
            description="This helps us to investigate an issue"
            onChange={handleExtraInfoChange}
          />
        </InputGroup>
      </InputGroup>

      <Side.Actions horizontal>
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={() => {
            onClose()
          }}
          successText="Thank you, issue was submitted"
          successDescription={
            <>
              You can find your requests{' '}
              <TextButton
                onClick={() => {
                  onClose()
                  navigateTo(ROUTES.TICKETS.ALL)
                }}
              >
                here
              </TextButton>
            </>
          }
          popupVariant="success-optional"
        />
      </Side.Actions>
    </>
  )
}

export const BugReportSide = ({ open, onClose }: BugReportSideProps) => {
  return (
    <SideBar
      useLayout
      isOpen={open}
      onClose={onClose}
      variant="wide"
      title="Report a bug"
    >
      <Form api={ticketsRequests} isExistingForm={false} disableLocalStorageCaching>
        <BugReportForm onClose={onClose} />
      </Form>
    </SideBar>
  )
}
