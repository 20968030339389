import { useMemo } from 'react'
import { Token } from '@revolut/ui-kit'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { useGetReviewGradesMap } from '@src/utils/grades'
import { PerformanceReviewAnalyticsGroupTypes } from '@src/interfaces/performance'
import {
  PerformanceReviewAnalyticsSortKeys,
  useGetPerformanceReviewAnalytics,
} from '@src/api/performance'
import { BaseColor } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { CHART_COLORS } from '@src/pages/Forms/QueryForm/components/Charts/constants'
import { getColorByIndex } from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import {
  GradeDistributionDataKey,
  GradeDistributionItemData,
} from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/Items'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

const COLORS: BaseColor[] = [
  ...new Set<BaseColor>([
    'teal',
    'cyan',
    'lightBlue',
    'blue',
    'coldBlue',
    'deepPurple',
    ...CHART_COLORS,
  ]),
]

interface Props {
  data: GradeDistributionItemData[]
  dataKeys: GradeDistributionDataKey[]
  isLoading: boolean
}

export const useChartData = (reviewCycle: ReviewCyclesInterface | null): Props => {
  const { data: analyticsData, isLoading: isAnalyticsDataLoading } =
    useGetPerformanceReviewAnalytics(
      PerformanceReviewAnalyticsGroupTypes.Seniority,
      {
        cycle__id: reviewCycle?.id,
      },
      [
        {
          sortBy: PerformanceReviewAnalyticsSortKeys.Seniority,
          direction: SORT_DIRECTION.DESC,
        },
      ],
      { enabled: !!reviewCycle?.id },
    )
  const { gradesMap, isLoading: isGradesLoading } = useGetReviewGradesMap()

  const dataKeys = useMemo(() => {
    if (!analyticsData) {
      return []
    }

    return analyticsData.map(({ group }, index) => ({
      id: index,
      label: group.name,
      value: group.name,
      color: Token.colorChannel[getColorByIndex(index, COLORS)],
    }))
  }, [analyticsData])

  const data = useMemo(() => {
    if (!gradesMap || !analyticsData) {
      return []
    }

    const totalGradesCount = analyticsData.reduce<number>(
      (count, { grades_total }) => count + grades_total,
      0,
    )

    return Object.entries(gradesMap).map<GradeDistributionItemData>(
      ([gradeId, gradeLabel]) => {
        const gradeCount = analyticsData.reduce<number>(
          (count, { grades }) =>
            count + (grades.find(({ id }) => id === gradeId)?.count ?? 0),
          0,
        )

        return {
          ...analyticsData.reduce<GradeDistributionItemData>(
            (bySeniority, { group, grades }) => {
              const groupDistribution = grades.find(({ id }) => id === gradeId)

              return {
                ...bySeniority,
                [group.name]: groupDistribution
                  ? parseFloat((groupDistribution.count / totalGradesCount).toFixed(5)) *
                    100
                  : 0,
              }
            },
            {
              gradeCount,
              totalGradesCount,
              targetDistribution: reviewCycle?.grade_distribution
                ? Math.round(reviewCycle.grade_distribution[gradeId] * 100)
                : 0,
              label: gradeLabel,
              tooltip: '',
            },
          ),
        }
      },
    )
  }, [analyticsData, gradesMap])

  return {
    data,
    dataKeys,
    isLoading: isAnalyticsDataLoading || isGradesLoading,
  }
}
