import React from 'react'
import MainHeader from '@src/features/MainHeader/MainHeader'
import Apps from '@src/pages/Hub/Apps/Apps'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import Integrations from '@src/pages/Hub/Integrations/Integrations'
import { Route, Switch } from 'react-router-dom'

export const getTabs = () => [
  {
    title: 'My apps',
    path: ROUTES.FEATURES.APPS,
    url: ROUTES.FEATURES.APPS,
    canView: true,
    component: Apps,
  },
  {
    title: 'Integrations',
    path: ROUTES.FEATURES.INTEGRATIONS,
    url: ROUTES.FEATURES.INTEGRATIONS,
    canView: true,
    component: Integrations,
  },
]

// TODO: https://revolut.atlassian.net/browse/REVCOR-3625 remove or bring back "Features"
const HubLandingPage = () => {
  const filteredTabs = getTabs().filter(tab => tab.canView)

  return (
    <PageWrapper pb="s-24" pl={0}>
      <MainHeader title="Features" tabs={filteredTabs} />
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default HubLandingPage
