import { useMainSectionsConfig } from '@src/hooks/useMainSectionsConfig'
import React, { useRef } from 'react'
import { Route, Switch } from 'react-router-dom'
import HubLandingPage from '@src/pages/Hub/HubLandingPage'
import { ROUTES } from '@src/constants/routes'

const MainSections = () => {
  const mainSectionsConfig = useMainSectionsConfig()

  /** useMainSectionsConfig result changes based on global settings, which are loaded after initial render.
   * Taking a reference of the initial render result, to prevent rerendering and making unnecessary requests in the sections */
  const sections = useRef(mainSectionsConfig).current

  return (
    <Switch>
      {/* TODO: https://revolut.atlassian.net/browse/REVCOR-3625 remove or bring back "Features" */}
      <Route path={[ROUTES.FEATURES.APPS, ROUTES.FEATURES.INTEGRATIONS]}>
        <HubLandingPage />
      </Route>
      {sections.map(section => (
        <Route
          exact
          key={section.name}
          path={section.path || section.url}
          render={props => <section.component {...props} />}
        />
      ))}
    </Switch>
  )
}

export default MainSections
