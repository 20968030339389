import React from 'react'
import { Box, Flex, Text, Token } from '@revolut/ui-kit'
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent'
import { GradeDistributionDataKey } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/Items'

interface Props {
  props: LegendProps
  dataKeys: GradeDistributionDataKey[]
}

export const Legend = ({ props: { payload }, dataKeys }: Props) => (
  <Flex columnGap="s-12" rowGap="s-2" justifyContent="center" flexWrap="wrap">
    {(payload ?? []).map(barItem => {
      const key = dataKeys.find(
        dataKey => String(dataKey.value) === String(barItem.dataKey),
      )

      if (!key) {
        return null
      }

      return (
        <Flex key={String(key.value)} alignItems="center" gap="s-4">
          <Box
            bg={barItem.color}
            width="s-12"
            height="s-12"
            border={`1px solid ${Token.color.greyTone10}`}
            borderRadius={Token.radius.r2}
          />
          <Text fontSize="small" color={Token.color.greyTone50} use="div">
            {key.label}
          </Text>
        </Flex>
      )
    })}
  </Flex>
)
