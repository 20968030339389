import React from 'react'
import { BarProps } from 'recharts'

const MAX_BAR_SIZE = 150
const BAR_RADIUS = 3
const LINE_STROKE_WIDTH = 4

export const BarShape = ({ fill, x, y, width, height }: BarProps) => {
  const rectHeightWithGap = Number(height) - LINE_STROKE_WIDTH
  const rectHeight = rectHeightWithGap > 0 ? rectHeightWithGap : 0

  return (
    <svg>
      <rect
        max={MAX_BAR_SIZE}
        rx={BAR_RADIUS}
        ry={BAR_RADIUS}
        fill={fill}
        x={x}
        y={Number(y) + LINE_STROKE_WIDTH / 2}
        width={width}
        height={rectHeight}
      />
    </svg>
  )
}
